import React from 'react'
// @ts-ignore
import ReactDOM from 'react-dom/client'
import L from '@loadable/component'

export const fallback = <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><img src="https://files.un-no.net/static/loading.svg"
                                                                                                                       alt="loading"/></div>
const App = L(() => import(/* webpackChunkName: "app" */'./app'), { fallback })

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<App/>)